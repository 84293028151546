import { useI18n } from 'vue-i18n';

export const useOrdinal = (locale) => {
  const { t: gt } = useI18n({ useScope: 'global' });
  return {
    format(n) {
      const pr = new Intl.PluralRules(locale.value, { type: 'ordinal' });
      const rule = pr.select(n);
      return gt(`ordinal.${rule}`, { n });
    },
  };
};

export default useOrdinal;
